export default {
  tab: state => state.tab,
  touchParameters: state => state.touchParameters,
  comboParameters: state => state.comboParameters,
  selectedSwitchPalette: state => state.selectedSwitchPalette,
  selectedComboPalette: state => state.selectedComboPalette,
  selectedSwitchWood: state => state.selectedSwitchWood,
  selectedComboWood: state => state.selectedComboWood,
  updateItem: state => state.updateItem,
  icons: state => state.icons,
  currentTouchArtikul: state => {
    let a = state.touchParameters.material.charAt(0).toUpperCase();
    if (
        state.touchParameters.series === 'A85' ||
        state.touchParameters.series === 'A90' ||
        state.touchParameters.series === 'C85' ||
        state.touchParameters.series === 'W85'
    ) {
      a = ''
    }
    a += state.touchParameters.series ? state.touchParameters.series : 'XX';
    a += '-';

    if (state.touchParameters.series === 'A85' || state.touchParameters.series === 'A90') {
      switch (state.touchParameters.interface) {
        case 'KNX':
          a += 'K'
          break;
        case 'DRY CONTACT':
          a += 'B'
          break;
        default:
          a += 'X'
      }
      a += state.touchParameters.sensorsCount

      let no_button_icons_count = state.icons.filter(item => item === 'no_button').length;
      if (state.touchParameters.buttonsCount > -10){
        a += state.touchParameters.sensorsCount - no_button_icons_count
      }
    } else {
      a += +state.touchParameters.sensorsCount === 1 || +state.touchParameters.sensorsCount === 4 ? '' : state.touchParameters.orientation ? state.touchParameters.orientation.charAt(0).toUpperCase() : 'X';
      switch (state.touchParameters.interface) {
        case 'KNX':
          a += 'K'
          break;
        case 'DRY CONTACT':
          a += '0'
          break;
        case '220 V':
          a += '1'
          break;
        case 'INPs/OUTs':
          a += '2'
          break;
        default:
          a += 'X'
      }
      a += state.touchParameters.sensorsCount ? state.touchParameters.sensorsCount : 'X'
    }
    a += '-';
    a += state.selectedSwitchPalette.dataArticul ? state.selectedSwitchPalette.dataArticul : 'XX';
    if (state.touchParameters.series === 'W85') {
      a += 3
      // a += state.selectedSwitchWood.dataArticul ? state.selectedSwitchWood.dataArticul : 'XX';
      a += state.selectedSwitchWood.dataArticul ? state.selectedSwitchWood.dataArticul + '-' + state.selectedSwitchWood.type : 'XX-X';
    } else {
      a += state.touchParameters.surface ? state.touchParameters.surface === 'Matte' ? '3' : '7' : 'X'
    }
    if (
        state.touchParameters.series !== 'A85' &&
        state.touchParameters.series !== 'A90' &&
        state.touchParameters.series !== 'W85'
    ) {
      a += state.touchParameters.inlay ? state.touchParameters.inlay.substr(0, 2).toUpperCase() : 'XX';
    }

    return a
  },
  currentComboArtikul: state => {
    let slots = Object.keys(state.comboParameters.selectedSlots).map(slot => state.comboParameters.selectedSlots[slot] ? state.comboParameters.selectedSlots[slot] : '**').slice(0, +state.comboParameters.slotsCount);
    let filteredSlots = [],
        currentIndex = 0,
        count_same = 0,
        switchInterface = 'X';
    switch (state.comboParameters.switchInterface) {
      case 'KNX':
        switchInterface = 'K'
        break;
      case 'DRY CONTACT':
        switchInterface = '0'
        break;
      case '220 V':
        switchInterface = '1'
        break;
      case 'INPs/OUTs':
        switchInterface = '2'
        break;
      default:
        switchInterface = 'X'
    }
    slots.forEach((slot, index) => {
      if (slot.length === 3 && slot[0] === 'H' && slot[2] === '8') {
        if (state.comboParameters.orientation === "Vertical") {
          filteredSlots.push(`V${switchInterface}8`)
        } else {
          filteredSlots.push(`H${switchInterface}8`)
        }
        // currentIndex++;
      } else if (slots[index - 1] && (slots[index - 1] === 'H08' || slots[index - 1] === 'H18' || slots[index - 1] === 'H28')) {
        // console.log('Пропуск')
      }

          // else if (
          //     (slot === 'R1') &&
          //     (state.tab === 'sockets') &&
          //     (
          //         filteredSlots[currentIndex] === 'R1' ||
          //         filteredSlots[currentIndex] === 'R2' ||
          //         filteredSlots[currentIndex] === 'R3' ||
          //         filteredSlots[currentIndex] === 'R4'
          //     )
          // ) {
          //   filteredSlots[currentIndex] = 'R' + (+filteredSlots[currentIndex].charAt(1) + 1);
          // } else if (slot === 'R1') {
          //   filteredSlots.push('R1')
      // }

      else if (slot === 'R1') {
        count_same = 0
        for (let key in state.comboParameters.selectedSlots){
          if(key <= state.comboParameters.slotsCount && state.comboParameters.selectedSlots[key] === 'R1'){
            count_same += 1
          }
        }
        if (count_same == state.comboParameters.slotsCount){
          filteredSlots = []
          filteredSlots[currentIndex] = 'R' + count_same
        }else {
          filteredSlots.push('R1')
        }
      }

      else if (slot === 'FB1') {
        count_same = 0
        for (let key in state.comboParameters.selectedSlots){
          if(key <= state.comboParameters.slotsCount && state.comboParameters.selectedSlots[key] === 'FB1'){
            count_same += 1
          }
        }
        if (count_same == state.comboParameters.slotsCount){
          filteredSlots = []
          filteredSlots[currentIndex] = 'FB' + count_same
        }else {
          filteredSlots.push('FB1')
        }
      }

      else if (slot === 'S1') {
        filteredSlots.push('S1')
      }

      // else if (
      //     (slot === 'F1') &&
      //     (state.tab === 'sockets') &&
      //     (
      //         filteredSlots[currentIndex] === 'F1' ||
      //         filteredSlots[currentIndex] === 'F2' ||
      //         filteredSlots[currentIndex] === 'F3' ||
      //         filteredSlots[currentIndex] === 'F4')
      // ) {
      //   filteredSlots[currentIndex] = 'F' + (+filteredSlots[currentIndex].charAt(1) + 1);
      // } else if (slot === 'F1') {
      //   filteredSlots.push('F1')
      // }

      else if (slot === 'F1') {
        count_same = 0
        for (let key in state.comboParameters.selectedSlots){
          if(key <= state.comboParameters.slotsCount && state.comboParameters.selectedSlots[key] === 'F1'){
            count_same += 1
          }
        }
        if (count_same == state.comboParameters.slotsCount){
          filteredSlots = []
          filteredSlots[currentIndex] = 'F' + count_same
        }else {
          filteredSlots.push('F1')
        }
      }

          // else if((slot === 'U1') && (filteredSlots[currentIndex] === 'U1' || filteredSlots[currentIndex] === 'U2' || filteredSlots[currentIndex] === 'U3' || filteredSlots[currentIndex] === 'U4')){
          //   filteredSlots[currentIndex] = 'U' + (+filteredSlots[currentIndex].charAt(1) + 1);
          // } else if (slot === 'U1'){
          //   filteredSlots.push('U1')
      // }

      else if (slot === 'U1') {
        count_same = 0
        for (let key in state.comboParameters.selectedSlots){
          if(key <= state.comboParameters.slotsCount && state.comboParameters.selectedSlots[key] === 'U1'){
            count_same += 1
          }
        }
        if (count_same == state.comboParameters.slotsCount){
          filteredSlots = []
          filteredSlots[currentIndex] = 'U' + count_same
        }else {
          filteredSlots.push('U1')
        }
      }

          // else if((slot === 'I1') && (filteredSlots[currentIndex] === 'I1' || filteredSlots[currentIndex] === 'I2' || filteredSlots[currentIndex] === 'I3' || filteredSlots[currentIndex] === 'I4')){
          //   filteredSlots[currentIndex] = 'I' + (+filteredSlots[currentIndex].charAt(1) + 1);
          // } else if (slot === 'I1'){
          //   filteredSlots.push('I1')
      // }

      else if (slot === 'I1') {
        count_same = 0
        for (let key in state.comboParameters.selectedSlots){
          if(key <= state.comboParameters.slotsCount && state.comboParameters.selectedSlots[key] === 'I1'){
            count_same += 1
          }
        }
        if (count_same == state.comboParameters.slotsCount){
          filteredSlots = []
          filteredSlots[currentIndex] = 'I' + count_same
        }else {
          filteredSlots.push('I1')
        }
      }

      else if (slot === 'J1') {
        count_same = 0
        for (let key in state.comboParameters.selectedSlots){
          if(key <= state.comboParameters.slotsCount && state.comboParameters.selectedSlots[key] === 'J1'){
            count_same += 1
          }
        }
        if (count_same == state.comboParameters.slotsCount){
          filteredSlots = []
          filteredSlots[currentIndex] = 'J' + count_same
        }else {
          filteredSlots.push('J1')
        }
      }

      // ===== ?

      else if (slot === 'ETH1') {
        filteredSlots.push('ETH1')
      } else if (slot === 'ETH2') {
        filteredSlots.push('ETH2')
      } else if (slot === 'HD1') {
        filteredSlots.push('HD1')
      } else if (slot === 'AS') {
        filteredSlots.push('AS')
      } else if (slot === 'TV1') {
        filteredSlots.push('TV1')
      } else if (slot === 'TV2') {
        filteredSlots.push('TV2')
      } else if (slot === 'B4') {
        if (state.comboParameters.switchInterface === 'KNX'){
          filteredSlots.push('K4')
        }else {
          filteredSlots.push('B4')
        }
        if (state.comboParameters.noButtonsCount > -1) {
          let filteredArray = state.comboParameters.slotsTouchIcons[index + 1].filter(item => item === 'no_button');
          filteredSlots.push(4 - filteredArray.length)
        }
      } else if (slot === 'B6') {
        if (state.comboParameters.switchInterface === 'KNX'){
          filteredSlots.push('K6')
        }else {
          filteredSlots.push('B6')
        }
        if (state.comboParameters.noButtonsCount > -1) {
          let filteredArray = state.comboParameters.slotsTouchIcons[index + 1].filter(item => item === 'no_button');
          filteredSlots.push(6 - filteredArray.length)
        }
      } else if (slot.length === 3 && (slot[0] === 'H' || slot[0] === 'V') && (slot[2] === '2')) {
        filteredSlots.push(slot[0] + switchInterface + slot[2])
        // currentIndex++;
      } else if (slot.length === 2 && (slot[1] === '1' || slot[1] === '4')) {
        filteredSlots.push(switchInterface + slot[1])
        // currentIndex++;
      } else {
        filteredSlots.push(slot)
        // currentIndex++;
      }
    })


    let a = 'Combo ';
    a += state.comboParameters.slotsCount;

    a += state.comboParameters.orientation ? state.comboParameters.orientation.charAt(0).toUpperCase() : 'X';

    if (
        state.comboParameters.series === 'A85' ||
        state.comboParameters.series === 'A90' ||
        state.comboParameters.series === 'C85' ||
        state.comboParameters.series === 'W85'
    ) {
      a += state.comboParameters.series
    } else {
      a += state.comboParameters.series ? `S${state.comboParameters.series}` : 'XXX'
    }

    a += '-'
    a += filteredSlots.join('');
    a += '-';
    a += state.selectedComboPalette.dataArticul ? state.selectedComboPalette.dataArticul : 'XX';
    if (state.comboParameters.series === 'W85') {
      a += 3
      a += state.selectedComboWood.dataArticul ? state.selectedComboWood.dataArticul + '-' + state.selectedComboWood.type : 'XX-X';
    } else {
      a += state.comboParameters.surface ? state.comboParameters.surface === 'Matte' ? '3' : '7' : 'X';
    }
    if (
        filteredSlots.includes('K1') ||
        filteredSlots.includes('VK2') ||
        filteredSlots.includes('HK2') ||
        filteredSlots.includes('K4') ||
        filteredSlots.includes('HK8') ||
        filteredSlots.includes('VK8') ||
        filteredSlots.includes('01') ||
        filteredSlots.includes('11') ||
        filteredSlots.includes('21') ||
        filteredSlots.includes('V02') ||
        filteredSlots.includes('V12') ||
        filteredSlots.includes('V22') ||
        filteredSlots.includes('H02') ||
        filteredSlots.includes('H12') ||
        filteredSlots.includes('H22') ||
        filteredSlots.includes('04') ||
        filteredSlots.includes('14') ||
        filteredSlots.includes('24') ||
        filteredSlots.includes('H08') ||
        filteredSlots.includes('H18') ||
        filteredSlots.includes('H28') ||
        filteredSlots.includes('V08') ||
        filteredSlots.includes('V18') ||
        filteredSlots.includes('V28')
    ) {
      if (state.comboParameters.series !== 'W85') {
        a += state.comboParameters.switchInlay ? state.comboParameters.switchInlay.substr(0, 2).toUpperCase() : 'XX';
      }
    }
    if (
        state.comboParameters.colorSocket !== 'NF' &&
        (
            slots.slice(0, state.comboParameters.slotsCount).includes('R1') ||
            slots.slice(0, state.comboParameters.slotsCount).includes('FB1') ||
            slots.slice(0, state.comboParameters.slotsCount).includes('RCHac') ||
            slots.slice(0, state.comboParameters.slotsCount).includes('RCHc')
        )) {
      a += '-';
      a += state.comboParameters.colorSocket ? state.comboParameters.colorSocket : 'XX'
    }
    return a
  }
}
